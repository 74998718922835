$primary-darkest: #00464d;
$primary-dark: #027882;
$primary-light: #83c5be;
$primary-lightest: #c0faf5;

$secondary-darkest: #e8805a;
$secondary-dark: #e29578;
$secondary-light: #ffddd2;
$secondary-lightest: #fff4f0;
$background: #edf6f9;
