@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  scroll-behavior: smooth;
  scroll-padding-top: 5rem;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
}
a:hover {
  cursor: url("../assets/cursor-hand-main2.png"), auto;
}
input,
textarea:hover {
  cursor: url("../assets/cursor-text-main.png"), auto;
}
body {
  font-family: "Poppins", sans-serif;
  background-color: #83c5be;
  cursor: url("../assets/cursor-main2.png"), auto;
}
/* @media screen and (max-width: 1400px) {
  body {
    display: none;
  }
} */

/* -------------preloader-------------- */
.preloader {
  background: #83c5be url("../assets/preloader2-large-fast.gif") no-repeat
    center center;
  background-size: 5%;
  position: fixed;
  width: 100%;
  height: 100dvh;
  z-index: 100;
  transition: all 0.3s ease-out;
}
