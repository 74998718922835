@use "colors" as c;
@use "fonts" as f;

.contact {
  margin-top: 10rem;
  width: 100vw;
  // height: 100vh;

  > .container {
    width: min(100%, 1240px);
    padding: 0 20px;
    margin-inline: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    align-items: center;
    > .form {
      display: flex;
      flex-direction: column;
      // grid-template-columns: repeat(2, 1fr);
      // > .grid-col-span-2 {
      //   grid-column: span 2;
      //}
      gap: 1rem;
      align-items: center;
      justify-content: center;

      > h2 {
        text-align: center;
        color: c.$primary-darkest;
        font-size: 2.7rem;
        font-weight: 200;
        letter-spacing: 2px;
        text-transform: uppercase;
        margin-bottom: 1rem;
      }
      > input,
      > button,
      > textarea {
        padding: 1rem;
        width: 70%;
        outline: none;
        font-weight: 400;
        font-size: 1.1rem;
        border: none;
        border-radius: 15px;
        background-color: rgba(255, 243, 237, 0.8);
      }

      > .message {
        font-family: "Poppins", sans-serif;
        width: 70%;
        height: 100px;
        font-weight: 400;
        font-size: 1.1rem;
        resize: none;
      }
      > button {
        cursor: pointer;
        background-color: c.$primary-darkest;
        color: c.$secondary-lightest;
        text-transform: uppercase;
        margin: 1rem;
        font-weight: 600;
        font-size: 1rem;
        // width: 180px;
        &:hover {
          background-color: c.$primary-dark;
        }
      }
    }
    > aside {
      width: 100%;
      height: 100%;
      border-radius: 50px;
      background-color: c.$primary-darkest;
      display: grid;
      place-items: center;
      > img {
        width: 74%;
        // height: 75%;
        object-fit: contain;
        filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.6)) hue-rotate(300deg);
        // filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.6)) hue-rotate(180deg);
        animation: updown 0.7s infinite linear alternate;
      }
    }
  }
  .btn-disabled {
    background-color: #02778238 !important;
    cursor: not-allowed !important;
  }
}
@keyframes updown {
  from {
    transform: translateY(5px);
  }
  to {
    transform: translateY(-5px);
  }
}
