@use "colors" as c;
@use "fonts" as f;

// --------trying single level nesting---------
.projects {
  // height: 100vh;
  margin-top: 1rem;
  padding-top: 1px;
  // padding-top: 13rem;

  .container {
    width: min(100%, 1240px);
    margin-inline: auto;
    margin-top: 8rem;

    > .carousel {
      width: 100%;
      .each-image-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: c.$primary-darkest;
        border-radius: 36px;
        width: 320px;
        margin-inline: 20px;
        padding: 40px 0;
        gap: 2rem;
        color: white;
        a {
          display: flex;
          justify-content: center;
          width: 290px;
          height: 280px;
          img {
            border-radius: 20px;
            width: 80%;
            object-fit: cover;
          }
        }

        .legend {
          text-align: center;
          padding-inline: 44px;
        }
      }
    }
  }

  // ----------react-multi-carousel-edits-----------
  .react-multi-carousel-item {
    display: flex;
    justify-content: center;
  }
  .react-multiple-carousel__arrow {
    z-index: 8;
  }
  // .react-multiple-carousel__arrow--left {
  //   left: 0;
  // }
  // .each-image-div {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   background-color: #02778296;
  //   border: none;
  //   border-radius: 2vw;
  //   // box-shadow: 0 0 26px -8px c.$primary-darkest;
  //   width: 300px;
  //   height: 360px;
  //   padding: 2.6rem 1rem;
  //   margin: 1rem 0;
  //   transition: all 0.2s ease-in-out;
  //   &:hover {
  //     transform: scale(1.06);
  //     // box-shadow: 3px 3px 9px 1px c.$primary-darkest;
  //   }
  // }
  //   .carousel-image {
  //     border-radius: 2vw;
  //     overflow: hidden;
  //     width: 200px;
  //     height: 220px;
  //     background-color: blue;

  //     > img {
  //       // border-radius: 2vw;

  //       width: 200px;
  //       height: 220px;
  //       object-fit: cover;
  //       object-position: center;
  //     }
  //   }
  //   .legend {
  //     padding-top: 1.4rem;
  //     font-weight: 500;
  //     font-size: 1.1rem;
  //   }
}
