@use "colors" as c;
@use "fonts" as f;

.technologies {
  margin-top: 8rem;
  padding: 3.6rem 0;
  background-color: c.$primary-dark;
  > .container {
    width: min(100%, 1240px);
    margin-inline: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    > .technologies-heading {
      font-size: f.$heading-large;
      letter-spacing: 1px;
      font-family: "Poppins", sans-serif;
      font-weight: 700;
      color: c.$secondary-lightest;
      filter: drop-shadow(0 6px 12px rgba(0, 0, 0, 0.7));
    }
    > .technologies-container {
      margin-top: 50px;

      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      justify-content: center;

      img {
        width: 5rem;
        height: 5rem;
        transition: all 0.2s ease-in-out;
        object-fit: contain;

        filter: drop-shadow(0 0 16px rgba(0, 0, 0, 0.5));

        &:hover {
          transform: scale(1.2);
        }
      }
      p {
        text-align: center;
        color: c.$secondary-lightest;
        font-size: f.$paragraph-small;
        text-shadow: 0 0 14px rgb(0, 0, 0);
      }
    }
  }
}
