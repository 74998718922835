@use "colors" as c;
@use "fonts" as f;
@media screen and (max-width: 1400px) {
  // ---------global---------

  // ---------navbar---------
  // ---------home---------
  // ---------about---------
  .about {
    clip-path: initial;
  }
  // ---------technologies---------
  // ---------projects---------
  // ---------contact---------
  // ---------footer---------
}
@media screen and (max-width: 1280px) {
  // ---------global---------
  .container {
    // background-color: red;
    // padding-inline: 3rem !important;
  }

  // ---------navbar---------
  // ---------home---------
  // ---------about---------

  // ---------technologies---------
  // ---------projects---------
  // ---------contact---------
  // ---------footer---------

  .home {
    .container {
      padding: 3rem;
    }
  }
}

@media screen and (max-width: 1110px) {
  // ---------global---------
  // ---------navbar---------
  // ---------home---------
  // .home {
  //   h1 {
  //     font-size: 3.6rem !important;
  //   }
  // }
  .home {
    > .container {
      > .hero {
        > .hero-text {
          > h1 {
            font-size: 2.6rem;
          }
        }
      }
    }
  }

  // ---------about---------
  // ---------technologies---------
  // ---------projects---------
  // ---------contact---------
  // ---------footer---------
}
// @media screen and (max-width: 1024px) {
//   // ---------global---------

//   // ---------navbar---------
//   // ---------home---------
//   // ---------about---------
//   // ---------technologies---------
//   // ---------projects---------
//   // ---------contact---------
//   // ---------footer---------
// }
@media screen and (max-width: 904px) {
  // ---------global---------

  // ---------navbar---------
  // ---------home---------
  // .home {
  //   > .container {
  //     > .hero {
  //       > .hero-text {
  //         > h1 {
  //         }
  //       }
  //     }
  //   }
  // }
  // ---------about---------
  // ---------technologies---------
  // ---------projects---------
  // ---------contact---------
  // ---------footer---------
}
@media screen and (max-width: 880px) {
  // ---------global---------

  // ---------navbar---------
  // ---------home---------
  .home {
    h1 {
      font-size: 3rem !important;
    }
  }
  // ---------about---------
  // ---------technologies---------
  // ---------projects---------
  // ---------contact---------
  // ---------footer---------
}
// @media screen and (max-width: 844px) {
//   // ---------global---------

//   // ---------navbar---------
//   // ---------home---------
//   .home {
//     h1 {
//       font-size: 2.4rem !important;
//     }
//   }
//   // ---------about---------
//   // ---------technologies---------
//   // ---------projects---------
//   // ---------contact---------
//   // ---------footer---------
// }
@media screen and (max-width: 844px) {
  // ---------global---------

  // ---------navbar---------
  // ---------home---------
  .home {
    .container {
      .hero {
        flex-direction: column;
        gap: 5rem;
        margin-top: -40px;
        .hero-text {
          width: 100%;
          text-align: center;
          // .hero-text-heading {
          //   font-size: 3rem !important;
          // }
        }
        .hero-img-tilt {
          max-width: 300px;
          margin-inline: auto;
          img {
            max-width: 230px;
            max-height: 230px;
          }
        }
      }
    }
  }
  // ---------about---------
  // ---------technologies---------
  // ---------projects---------
  // ---------contact---------
  // ---------footer---------
}
@media screen and (max-width: 768px) {
  // ---------global---------

  // ---------navbar---------
  .nav {
    .container {
      main {
        gap: 8px;
      }
    }
  }
  // ---------home---------
  // ---------about---------
  // ---------technologies---------
  // ---------projects---------
  // ---------contact---------
  // ---------footer---------
}
@media screen and (max-width: 750px) {
  // ---------global---------

  // ---------navbar---------
  // .nav {
  //   .container {
  //     main {
  //       gap: 0;
  //     }
  //   }
  // }
  // ---------home---------
  // ---------about---------
  // ---------technologies---------
  .technologies {
    .container {
      padding-inline: 3rem;
    }
    .technologies-heading {
      text-align: center;
    }
  }
  // ---------projects---------
  // ---------contact---------
  .contact {
    .container {
      display: flex;
    }
    .form {
      width: 100%;
      // margin-inline: auto !important;
    }
    aside {
      display: none !important;
    }
  }
  // ---------footer---------
}
@media screen and (max-width: 710px) {
  // ---------global---------
  .preloader {
    background-size: 17%;
  }
  // ---------navbar---------
  // ---------home---------
  // ---------about---------
  // ---------technologies---------
  .technologies {
    .container {
      .technologies-heading {
        font-size: 1.8rem !important;
      }
    }
  }
  // ---------projects---------
  // ---------contact---------
  // ---------footer---------
}
@media screen and (max-width: 680px) {
  // ---------global---------

  // ---------navbar---------
  .nav {
    .container {
      main {
        border-left: 2px solid c.$primary-light;
        position: absolute;
        display: flex;
        flex-direction: column;
        right: -300px;
        top: 34px;
        height: 100vh;
        padding: 1.2rem;
        gap: 1.2rem;

        background-color: c.$primary-darkest;
        transition: all 0.2s ease-out;
        .nav-email.push-down {
          margin-top: 180px !important;
        }
        .nav-links {
          all: unset;
          border: none;
          border-radius: 10px;
          // width: 100%;
          width: 110px;
          padding-inline: 3rem !important;
          // background-color: c.$background;
          padding: 8px 34px;
          color: white;
          text-align: center;
          font-size: 1.2rem;
          cursor: url("../assets/cursor-hand-main2.png"), auto;
          transition: all 0.2s ease-in-out;
          a {
            color: white;
          }
          &:hover {
            border-radius: 10px;
            background-color: c.$primary-light;
            color: c.$primary-darkest;
            > a {
              color: c.$primary-darkest;
            }
          }
        }
      }
      .menu-icon {
        display: block;
        cursor: url("../assets/cursor-hand-main2.png"), auto;
        // background-color: red;
      }
    }
  }
  .active-menu {
    right: 0 !important;
  }
  // ---------socialsdropdown---------------------------------------------------
  .socials-dropdown {
    left: 28px;
    top: 190px;
    background-color: transparent;
    border-radius: 0;
    border: none;

    ul {
      li {
        padding-top: 8px !important;
        padding-bottom: 6px !important;

        border-bottom: 2px solid c.$primary-dark;
        i {
          color: c.$primary-light !important;
        }
        display: flex;
        justify-content: center !important;
      }
    }
  }
  // ---------home---------
  // -------in844 works without !important but heere its needed?---------
  .home {
    .hero {
      margin-top: -90px !important;
    }
    h1 {
      font-size: 2.1rem !important;
    }
    .hero-img-tilt {
      max-width: 300px;
      margin-inline: auto;
      img {
        max-width: 200px !important;
        max-height: 200px !important;
      }
    }
  }
  // ---------about---------
  .about {
    margin-top: -40px;
  }
  // ---------technologies---------
  // ---------projects---------
  // ---------contact---------
  // ---------footer---------
}
@media screen and (max-width: 512px) {
  // ---------global---------

  // ---------navbar---------
  // ---------home---------

  .home {
    > .container {
      > .hero {
        > .hero-text {
          > h1 {
            font-size: 2rem !important;
          }
        }
        .hero-img-tilt {
          width: 45%;
          aspect-ratio: 1;
        }
        .hero-img {
          display: flex;
          justify-content: center;
          > img {
            border: none;
            border-radius: 53% 47% 31% 69% / 68% 68% 32% 32%;
            width: 100%;
            height: 100%;
            box-shadow: 5px 5px 22px -8px black;
          }
        }
      }
    }
  }
  // ---------about---------
  .about {
    margin-top: -60px;
  }
  // ---------technologies---------
  // ---------projects---------
  // ---------contact---------
  // ---------footer---------
}
@media screen and (max-width: 480px) {
  // ---------global---------

  // ---------navbar---------
  // ---------home---------
  // ---------about---------
  // ---------technologies---------
  // ---------projects---------
  // ---------contact---------
  // ---------footer---------
}

// ---------height-----------
@media screen and (max-height: 650px) {
  .home {
    .container {
      .hero {
        flex-direction: row;
        > .hero-text {
          > h1 {
            font-size: 2rem !important;
          }
        }
      }
    }
  }
}
