@use "colors" as c;

.socials-dropdown {
  position: absolute;
  left: 0;
  top: 60px;
  min-width: 150px;
  background-color: c.$primary-lightest;
  border: 3px solid c.$primary-dark;
  overflow: hidden;
  border-radius: 20px;
  padding: 1rem 1.4rem;

  > .dropdown-ul {
    > .list-item {
      padding: 4px 8px;
      list-style: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      > i {
        color: rgb(0, 107, 109);
      }
      > a {
        color: c.$primary-darkest;
      }
    }
  }
}
